import React from "react";
import { IBundleProduct } from "../Interfaces/IBundleProduct";
import { IProductCategory } from "squde-wp-api/IProductCategory";

interface IBundleContext {
    selectedBundle?: IBundleProduct;
    setSelectedBundle: (value: IBundleProduct|undefined) => void;
    selectedBundleCategory?: IProductCategory;
    setSelectedBundleCategory: (value: IProductCategory|undefined) => void;
    bundles: IBundleProduct[];
    types: IProductCategory[];
}

export const BundleContext = React.createContext({
    selectedBundle: undefined,
    setSelectedBundle: () => {},
    selectedBundleCategory: undefined,
    setSelectedBundleCategory: () => {},
    bundles: [],
    types: [],
} as IBundleContext)
