import React, { lazy, useEffect } from "react";
import "./AppContainer.scss";
import { Debug } from "../../Components/Debug/Debug";
import { TopBar } from "../../Components/TopBar/TopBar";
import { Route, Switch } from "react-router";
import { withRouter } from 'react-router-dom';
import { Page } from "../../Pages/Page/Page";
import { Footer } from "../../Components/Footer/Footer";
import { NewsletterSubscribe } from "../../Components/NewsletterSubscribe/NewsletterSubscribe";
import { PageLoader } from "../../Components/PageLoader/PageLoader";

const Preview = lazy(() => import('../Preview/Preview'));

// const isProduction = window.location.toString().split('/').filter((str: string, index: number) => index < 3).join('/') === 'https://eps-presentation.nl';
// ReactGA.initialize(isProduction ? 'UA-53991453-1' : 'UA-155236646-2');

interface IProps {
    history?: any
}
interface ILocation {
    hash: string
    key: string
    pathname: string
    search: string
    state: any
}

function AppContainer({history}: IProps) {
    const showFooter = false;



    useEffect(() => {
        const onChange = (location: ILocation, action: string) => {

            // console.log({ location });
            // const dataLayer = get(window, 'dataLayer');
            // dataLayer?.push({
            //     event: 'pageview',
            //     page: {
            //         url: window.location.pathname + window.location.search,
            //         // title: title
            //     }
            // });
            // ReactGA.pageview(window.location.pathname + window.location.search);
            const parts = location.pathname.split('/').filter((word: string) => word.length > 0);

            const body = document.getElementsByTagName('body')[0];
            body.setAttribute('class', parts.join(' '));
        };

        onChange({
            hash: "",
            key: "",
            pathname: '/' + document.location.href.toString().split('/').filter((t: string, index: number) => index > 2).join('/'),
            search: "",
            state: null
        }, 'PUSH');

        return history.listen(onChange);
    }, [history]);

    const isPreview = window.location.toString().split('/preview/').length > 1;


    return (
        <>
            {isPreview ? <></> : (
                <>
                    <Debug/>
                    <TopBar/>
                </>
            )}
            <div className="route-wrapper">
                <Switch>
                    <Route path={"/preview/:type/:id"} component={Preview} />
                    <Route path={"/preview/:type"} component={Preview} />

                    <Route path="/projecten/:slug" render={({ match, history }) => (
                        <Page history={history} match={match} type={'case'} />
                    )} />
                    <Route path="/nieuws/:slug" render={({ match, history }) => (
                        <Page history={history} match={match} type={'singlePostByQuery'} />
                    )} />
                    <Route path="/product/:slug" render={({ match, history }) => (
                        <Page history={history} match={match} type={'singleProductByQuery'} />
                    )} />
                    <Route path="/bundels/:slug" render={({ match, history }) => (
                        <Page history={history} match={match} type={'bundelSet'} />
                    )} />

                    <Route path="/:anything/:anything2/:slug" component={Page}/>
                    <Route path="/:anything/:slug" component={Page}/>
                    <Route path="/:slug" component={Page}/>
                    <Route exact path="/" component={Page}/>
                </Switch>
                <PageLoader />
            </div>
            {!isPreview ? <NewsletterSubscribe /> : ''}
            {showFooter ? <Footer/> : ''}
        </>
    )
}
// @ts-ignore
export default withRouter(AppContainer);
