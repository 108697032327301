import "./Icon.scss";
import React from "react";

interface IProps {
    src: string
    className?: string
}

export function Icon(props: IProps) {
    const style = {
        maskImage: 'url(' + props.src + ')',
        WebkitMaskImage: 'url(' + props.src + ')'
    };

    const classes = props.className ? props.className.split(' ') : ['white'];
    classes.push('squde-icon');

    return (
        <span className={classes.join(' ')} style={style} />
    );
}