import React, { Suspense, useEffect } from 'react';
import { Router } from "./Containers/Router/Router";
import { SidebarContainer } from "./Containers/SidebarContainer/SidebarContainer";
import { BundleContainer } from "./Containers/BundleContainer/BundleContainer";
import { PageLoader } from "./Components/PageLoader/PageLoader";


const App = () => {
    // load recaptcha after a 500ms delay
    useEffect(() => {
        const timeout = setTimeout(() => {
            const script = document.createElement('script');
            script.src = 'https://www.google.com/recaptcha/api.js?render=6LdYp8AZAAAAAFDTt93SUG9KgguDd3DW9jDrcvmn';
            script.async = true;
            document.head.prepend(script);
        }, 500);
        return () => {
            clearTimeout(timeout);
        }
    }, []);

    return (
        <Suspense fallback={<PageLoader/>}>
            <BundleContainer>
                <SidebarContainer>
                    <Router/>
                </SidebarContainer>
            </BundleContainer>
        </Suspense>
    );
};

export default App;
