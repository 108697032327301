import React, { ReactElement, useEffect, useState } from "react";
import "./CheckboxInput.scss";
import { randomString } from "../../Helpers/strings";

interface IProps {
    name?: string
    title: string
    children?: ReactElement|ReactElement[]|string
    type?: string
    value?: string|number
    required?: boolean
    onChange?: (e: any) => void
    onBlur?: (e: any) => void
}

export function CheckboxInput(props: IProps) {
    const [ uid, setUid ] = useState();

    const {
        type = 'checkbox',
        value = 1
    } = props;

    useEffect(() => {
        if (!uid) {
            setUid('uid-' + (randomString(10,12)));
        }
    }, [uid]);
    return (
        <div className="checkbox-input-wrapper">
            <div className="checkbox-wrapper">
                <input
                    type={type}
                    name={props.name}
                    value={value}
                    id={uid}
                    required={props.required}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                />
                <label htmlFor={uid} />
            </div>
            <div className="ui-wrapper">
                <label htmlFor={uid}>{props.title}</label>
                {props.children ? (
                    <div className={"label-context"}>{props.children}</div>
                ) : <p></p>}
            </div>
        </div>
    )
}