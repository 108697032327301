import React from "react";

interface ISidebar {
    open?: boolean,
    setOpen: (openState: boolean) => void
    element?: string
    setElement: (elementName: string) => void
    setData: (data: undefined|{ [s: string]: string|boolean|number; }) => void
}

export const SidebarContext = React.createContext({
    setOpen: () => {},
    open: false,
    setElement: () => {},
    setData: () => {},
} as ISidebar);