import React, { useEffect, useRef } from "react";
import "./LoaderImage.scss";
import { Loader } from "../Loader/Loader";
import placeholder from "../../resources/img/shapes/placeholder.svg";

interface IProps extends Omit<React.HTMLAttributes<HTMLImageElement>, 'className' | 'srcSet'> {
    class?: string;
    srcset?: string;
    src?: string;
    alt?: string;
}

export function LoaderImage({class: className, srcset: srcSet, src, ...props}: IProps) {
    const ref = useRef<HTMLImageElement>(null);
    const [loaded, setLoaded] = React.useState(false);
    const [inView, setInView] = React.useState(false);

    useEffect(() => {
        if (!ref.current) return;
        if (!inView) return;
        if (ref.current.complete) {
            setLoaded(true);
        } else {
            ref.current.addEventListener('load', () => {
                setLoaded(true);
            });
        }
    }, [inView]);

    useEffect(() => {
        if (!ref.current) return;
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setInView(true);
                }
            });
        });
        observer.observe(ref.current);
        return () => observer.disconnect();
    }, []);

    const classes = [className, 'loader-image', loaded ? 'loaded' : 'loading', inView ? 'in-view' : ''].filter(Boolean).join(' ');



    return (
        <>
            <Loader loading={inView && !loaded} />
            <img
                ref={ref}
                alt={'Loading...'}
                className={classes}
                {...(
                    inView ? {
                        src: src,
                        srcSet: srcSet
                    } : {
                        src: placeholder,
                        srcSet: placeholder
                    }
                )}
                {...props} />
        </>
    )
}