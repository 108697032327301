import React, { ReactElement, useState } from "react";
import { BundleContext } from "../../Context/BundleContext";
import { useBundles } from "../../Hooks/useBundles";
import { IBundleProduct } from "../../Interfaces/IBundleProduct";
import { IProductCategory } from "squde-wp-api/IProductCategory";

interface IProps {
    children: ReactElement|ReactElement[]|string
}

export function BundleContainer({ children }: IProps) {
    const {bundles, types} = useBundles(false);
    const [selectedBundle, setSelectedBundle] = useState<IBundleProduct|undefined>();
    const [selectedBundleCategory, setSelectedBundleCategory] = useState<IProductCategory|undefined>();


    return (
        <BundleContext.Provider value={{
            selectedBundle,
            setSelectedBundle,
            selectedBundleCategory,
            setSelectedBundleCategory,
            bundles,
            types,
        }}>
            {children}
        </BundleContext.Provider>
    )
}
