import "./Content.scss";
import React, { lazy, ReactElement, ReactNode } from "react";
import parse, { domToReact } from 'html-react-parser';
import { ICategory } from "../../Interfaces/ICase";
import { IEPSPage } from "../../Interfaces/IEPSPage";
import { IProduct } from "../../Interfaces/IProduct";
import { IPost } from "squde-wp-api/IPost";
import { IPage } from "squde-wp-api/IPage";
import { IBundleProduct } from "../../Interfaces/IBundleProduct";
import ActionBar from "../ActionBar/ActionBar";
import { LoaderImage } from "../LoaderImage/LoaderImage";
import { ContentLink } from "./ContentLink/ContentLink";


const BigSlider = lazy(() => import('../BigSlider/BigSlider'));
const BundleBar = lazy(() => import('../BundleBar/BundleBar'));
const BundlePicker = lazy(() => import('../BundlePicker/BundlePicker'));
const BundleSet = lazy(() => import('../../Pages/BundleSet/BundleSet'));
const BundleSlider = lazy(() => import('../BundleSlider/BundleSlider'));
const Case = lazy(() => import('../../Pages/Case/Case'));
const CasesBar = lazy(() => import('../CasesBar/CasesBar'));
const Contact = lazy(() => import('../../Pages/Contact/Contact'));
const CTA = lazy(() => import('../CTA/CTA'));
const FloatingBar = lazy(() => import('../FloatingBar/FloatingBar'));
const FigureWithText = lazy(() => import('../FigureWithText/FigureWithText'));
const IconHeading = lazy(() => import('../IconHeading/IconHeading'));
const ImageSlider = lazy(() => import('../ImageSlider/ImageSlider'));
const InfographicBar = lazy(() => import('../InfographicBar/InfographicBar'));
const LargeSEOContent = lazy(() => import('../LargeSEOContent/LargeSEOContent'));
const PageFooter = lazy(() => import('../../Containers/PageFooter/PageFooter'));
const ParallaxBackground = lazy(() => import('../ParallaxBackground/ParallaxBackground'));
const ParallaxContent = lazy(() => import('../ParallaxBackground/ParallaxContent/ParallaxContent'));
const ParallaxHeading = lazy(() => import('../ParallaxBackground/ParallaxHeading/ParallaxHeading'));
const ProductsBar = lazy(() => import('../ProductsBar/ProductsBar'));
const ProductWidget = lazy(() => import('../ProductWidget/ProductWidget'));
const Post = lazy(() => import('../../Pages/Post/Post'));
const Product = lazy(() => import('../../Pages/Product/Product'));
const Projects = lazy(() => import('../Projects/Projects'));
const SpacePicker = lazy(() => import('../SpacePicker/SpacePicker'));
const SpaceWidget = lazy(() => import('../SpaceWidget/SpaceWidget'));
const Socials = lazy(() => import('../Socials/Socials'));

interface IProps {
    children: IEPSPage
}



export const parseContentOptions = {
    replace: (props: any) => {
        const pageObject = props.children;
        const {attribs, children, name} = props;

        if (!attribs) return;

        switch (name) {
            case 'a':
                return <ContentLink {...attribs}>{domToReact(children, parseContentOptions)}</ContentLink>;
            case 'img':
                return <LoaderImage {...attribs} />;
            case 'actionbar':
                return (
                    <ActionBar action={attribs.action} actionText={attribs.action_text} title={attribs.title} className={attribs.class}/>
                );
            case 'big-slider':
                return <BigSlider className={attribs.class}>{domToReact(children, parseContentOptions) as []}</BigSlider>;
            case 'bundlepicker':
                return <BundlePicker variant={attribs.variant} />;
            case 'bundle-set':
                return <BundleSet product={pageObject as unknown as IBundleProduct}>{domToReact(children, parseContentOptions) as []}</BundleSet>;
            case 'case':
                return <Case project={pageObject as IPage}>{domToReact(children, parseContentOptions) as ReactElement[]}</Case>;
            case 'cases-bar':
                return <CasesBar title={attribs.title}>{domToReact(children, parseContentOptions) as ReactElement|ReactElement[]}</CasesBar>;
            case 'contact-page':
                return <Contact />;
            case 'cta':
                const className = (() => {
                    const styleClass = attribs.class
                        .split(' ')
                        .filter((str: string) => str.substr(0, 'is-style-'.length) === 'is-style-')
                        .map((str: string) => str.substr('is-style-'.length))
                        .map((str: string) => str === 'default' ? 'primary' : str)
                        [0];

                    return styleClass ? styleClass : 'primary';
                })();

                const { action } = attribs;

                return <CTA className={className} action={action}>{domToReact(children)}</CTA>;
            case 'div':
                if (attribs.class && attribs.class.split('wp-block-squde-clean-eps-eps-big-text').length > 1) {
                    return <LargeSEOContent>{domToReact(children, parseContentOptions)}</LargeSEOContent>;
                }
                break;
            case 'figure':
                if (attribs.class && attribs.class.split('wp-block-gallery').length > 1) {
                    return <ImageSlider>{domToReact(children, parseContentOptions) as ReactElement|ReactElement[]}</ImageSlider>;
                }
                if (attribs.class && attribs.class.split('is-style-with-text').length > 1) {

                    return <FigureWithText>{props}</FigureWithText>;
                }
                break;
            case 'eps-floating-bar':
                return <FloatingBar>{domToReact(children, parseContentOptions)}</FloatingBar>;
            case 'h1':
            case 'h2':
            case 'h3':
            case 'h4':
            case 'h5':
            case 'h6':
                if (attribs.class && attribs.class
                    .split(' ')
                    .indexOf('wp-block-squde-clean-eps-eps-title') >= 0 && !!attribs.icon) {
                    return <IconHeading className={attribs.class} icon={attribs.icon} tagName={name}>{domToReact(children, parseContentOptions)}</IconHeading>
                }
                if (attribs.class && attribs.class
                    .split(' ')
                    .indexOf('is-style-gigantic-title') >= 0) {
                    return <ParallaxHeading><h1>{domToReact(children, parseContentOptions)}</h1></ParallaxHeading>
                }
                break;
            case 'infographicbar':
                return React.createElement(InfographicBar, attribs);
            case 'parallax':
                const onAbsoluteTop = ['big-slider'];
                const onTop = ['infographicbar', 'eps-floating-bar'];
                const onBottom = ['cases-bar', 'socials', 'products-bar'];
                const innerChildren = children.filter((child: any) => {
                    return typeof child !== 'object'
                        || onBottom.concat(onTop).concat(onAbsoluteTop).indexOf(child.name) < 0;
                });
                const absoluteTopChildren = children.filter((child: any) => {
                    return typeof child === 'object'
                        && onAbsoluteTop.indexOf(child.name) >= 0;
                });
                const topChildren = children.filter((child: any) => {
                    return typeof child === 'object'
                        && onTop.indexOf(child.name) >= 0;
                });
                const bottomChildren = children.filter((child: any) => {
                    return typeof child === 'object'
                        && onBottom.indexOf(child.name) >= 0;
                });
                return (
                    <>
                        {domToReact(absoluteTopChildren, parseContentOptions)}
                        <div className="absolute-wrapper aligned-to-bottom cms-content-container" style={{bottom: 0, zIndex: 10}}>
                            {domToReact(topChildren, parseContentOptions)}
                            <ParallaxBackground className={""}>
                                <ParallaxContent className={"small-margin with-regular-content"}>
                                    <div className={"cms-content-wrapper"}>
                                        {domToReact(innerChildren, parseContentOptions)}
                                    </div>
                                </ParallaxContent>
                            </ParallaxBackground>
                            {domToReact(bottomChildren, parseContentOptions)}
                            <PageFooter/>
                        </div>
                    </>
                );
            case 'post':
                return <Post post={pageObject as IPost}>{domToReact(children, parseContentOptions) as ReactElement[]}</Post>;
            case 'product':
                return <Product product={pageObject as any as IProduct}>{domToReact(children, parseContentOptions) as ReactElement[]}</Product>;
            case 'products-bar':
                return <ProductsBar title={attribs.title}>{domToReact(children, parseContentOptions) as ReactElement|ReactElement[]}</ProductsBar>;
            case 'productwidget':
                return (
                    <ProductWidget id={attribs.product_id} columns={attribs.columns} rows={attribs.rows} direction={attribs.direction} showDescription={attribs.show_description === '1'} />
                );
            case 'projects':
                return (
                    <Projects>{domToReact(children, parseContentOptions)}</Projects>
                );
            case 'bundle-slider':
                return (
                    <BundleSlider
                        variant={attribs.variant}
                        title={attribs.bundletitle}
                        description={attribs.description}
                        categoryId={parseInt(attribs.bundlecategoryid)}
                    >{domToReact(children, parseContentOptions) as ReactNode[]}</BundleSlider>
                );
            case 'bundlebar':
                return (
                    <BundleBar
                        title={attribs.title}>{attribs.description}</BundleBar>
                );
            case 'socials':
                return (
                    <Socials
                        facebook={attribs.facebook}
                        twitter={attribs.twitter}
                        instagram={attribs.instagram}
                        pinterest={attribs.pinterest}
                    />
                );
            case 'space-picker':
                return <SpacePicker space={pageObject}/>;
            case 'space-widget':
                return (
                    <SpaceWidget
                        title={attribs.title}
                        heading={parse(attribs.heading, parseContentOptions) as ReactElement}
                    >{domToReact(children, parseContentOptions) as ReactNode[]}</SpaceWidget>
                );
        }
    }
};

export function Content(props: IProps) {
    const pageObject = props.children;
    let content = pageObject.content;

    content = !content ? '' : content;

    if (typeof content === 'object') {
        content = (content as any as {rendered: string}).rendered;
    }

    content = (content || pageObject.description) || '';

    const {
        type,
        _categories = [],
        slug = '',
        sku
    } = props.children;


    /* Preload URLs */
    // (() => {
    //     return content.split('src="').filter((str: string, index: number) => {
    //         return index > 0;
    //     }).map((str: string) => {
    //         return str.split('"')[0];
    //     }).filter((url: string) => {
    //         const ext = url.split('.').reverse()[0];
    //         return ['jpg', 'png', 'bmp', 'jpeg', 'webm'].indexOf(ext) >= 0;
    //     }).map((url: string) => {
    //         return <AssetsContext.Consumer>
    //             {({assets, addAsset}) => {
    //                 addAsset(url);
    //                 const basename = url.split('/').reverse()[0];
    //                 return <img src={url} alt={basename}/>
    //             }}
    //         </AssetsContext.Consumer>
    //     });
    // })();

    /* Remove empty paragraphs */
    content = (() => {
        return content.split('<p></p>').join('');
    })();



    if (type === 'case') {
        content = '<case>' + content + '</case>';
    } else if (type === 'post') {
        content = '<post>' + content + '</post>';
    } else if (type === 'grouped') {
        content = '<bundle-set>' + content + '</bundle-set>';
    } else if (sku !== undefined) {
        content = '<product>' + content + '</product>';
    }

    /* Add a big slider with the title if none was defined and a crumb is available*/
    content = (() => {
        const parts = content.split('</big-slider>');
        const post = props.children;
        let src;
        if (post && post.featured_image && parts.length === 1) {
            const { featured_image = {}} = post;
            src = featured_image.large ? featured_image.large : featured_image.full;
        } else if (post && post.images && post.images.length > 0) {
            src = post.images[0].src;
        }

        if (src) {
            const newHtml = '<big-slider><figure><img src="' + src + '" /><figcaption>' + (post.title || post.name) + '</figcaption></figure></big-slider>';
            return newHtml + content;
        }
        return content;
    })();



    /* A few items must be placed outside the parallax, in certain order */
    content = '<parallax>' + content + '</parallax>';

    /* Big sliders must always have at least one useless item to let its contents be an array */
    content = content.split('</big-slider>').join('<span></span></big-slider>');

    /* Space pages must have a space picker */
    if (_categories && _categories.filter((cat: ICategory) => cat.slug === 'ruimtes').length > 0) {
        content = content.split('<ActionBar').join('<space-picker></space-picker><ActionBar');
    }

    if (slug === 'contact') {
        content = content.split('</big-slider>').join('</big-slider><contact-page></contact-page>');
    }



    return (
        <>{parse(content, parseContentOptions )}</>
    )
}
