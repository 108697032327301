import React, { useEffect, useState } from "react";
import "./LatestNews.scss";
import parse  from 'html-react-parser';
import { wp } from "../../../Factories/Wordpress";
import { IPost } from "squde-wp-api/IPost";
import { Link } from "react-router-dom";

interface IProps {

}

export function LatestNews(props: IProps) {
    const [inView, setInView] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [newsItems, setNewsItems] = useState();

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setInView(true);
                }
            });
        });
        observer.observe(document.querySelector('.latest-news-wrapper') as Element);
        return () => observer.disconnect();
    }, []);

    useEffect(() => {
        if (!inView) return;
        if (!isLoading && !newsItems) {
            setIsLoading(true);
            (wp().posts.get({numberposts: '2'}) as Promise<IPost[]>).then((result: IPost[]) => {
                setNewsItems(result);
                setIsLoading(false);
            })
        }
    }, [newsItems, isLoading, inView]);

    const contents = (() => {
        if (!newsItems) {
            return [];
        }
        return newsItems.map((post: IPost, index: number) => {
            return <li key={index}>
                <Link to={post.url}>

                    <b>{post.title}</b><br />
                    {parse(post.excerpt, {
                        replace: (props: any) => {
                            const {type, data} = props;

                            if (type === 'text') {
                                props.data = data.split('[…]').join('…')
                                return props;
                            }
                        }})}
                </Link>
            </li>
        });
    })();

    return (
        <div className="latest-news-wrapper">
            <h4>Laatste nieuws</h4>
            <ul>
                {contents}
            </ul>
        </div>
    )
}