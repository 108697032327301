import React, { ReactElement } from "react";
import { Field } from "../../Field/Field";
import { SubmissionForm } from "../../../Components/SubmissionForm/SubmissionForm";
import { SidebarContext } from "../../../Context/SidebarContext";

interface IProps {
    heading?: ReactElement|ReactElement[]|string
    buttonClass?: string
    data?: { [s: string]: string|boolean|number; }
    leaveOpen?: boolean
}

export function BundleContactForm({buttonClass, heading, data, leaveOpen}: IProps) {
    let name: string;
    if (data && data.name) {
        name = data.name as string;
    }
    let email: string;
    if (data && data.email) {
        email = data.email as string;
    }
    const bundle = (data?.bundle ?? '') as string;
    return (
        <SidebarContext.Consumer>
            {({setOpen}) => {
                return <SubmissionForm leaveOpen={leaveOpen} buttonClass={buttonClass} before={(heading ||
                    <>
                        <h2>Contact over</h2>
                        <h1>{bundle.length > 0 ? bundle : 'Bundels'}</h1>
                        <p>Wat speelt er binnen de school? Waar ligt de focus? Allemaal vragen die we bespreken tijdens een gratis adviesgesprek. Laat je gegevens achter en wij nemen zo snel mogelijk contact met je op.</p>
                        <p>Liever telefonisch contact opnemen? - Dat kan ook via (0321) 33 26 55.</p>
                    </>
                )} formName={'Bundel offerte'} buttonText={"Verzenden"} whenDone={() => {
                    setTimeout(() => {
                        setOpen(false);
                    }, 5000);
                }}>
                    <section>
                        <Field name="name" title="Naam" inputType="text" required={true} value={name}/>
                        <Field name="functie" title="Functie" inputType="text" />
                        <Field name="school" title="School" inputType="text" required={true} />
                        <Field name="email" title="Email" inputType="text" required={true} value={email} />
                        <Field name="phone" title="Telefoonnummer" inputType="phone" />
                        <input type="hidden" name="bundle" value={bundle ?? 'Onbekend'} />
                        <Field name={"agreements"} title={"Ik ga akkoord met de algemene voorwaarden"} inputType={"checkbox"} required={true} />
                    </section>
                </SubmissionForm>;
            }}
        </SidebarContext.Consumer>
    );
}
