import React from "react";
import { IMenuItem } from "../../Interfaces/IMenuItem";
import { Link } from "react-router-dom";

interface IProps {
    menu: IMenuItem[]
}

export function Menu(props: IProps) {
    return (
        <ul className="regular-menu-wrapper">
            {props.menu.map((item: IMenuItem, index: number) => {
                let contents = (item.link ? <Link to={item.link}>{item.name}</Link> : <button onClick={item.onClick}>{item.name}</button>);
                return <li
                    key={index}>
                    {contents}
                </li>
            })}
        </ul>
    )
}