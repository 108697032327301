import React from "react";
import "./Loader.scss";

interface IProps {
    loading?: boolean;
}

export function Loader({ loading = false }: IProps) {
    return (
        <div className={`loader ${loading ? 'loading' : ''}`}>
            <div />
            <div />
            <div />
        </div>
    )
}