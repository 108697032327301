import React, { ReactNode, useEffect, useState } from "react";
import { Button } from "../Button/Button";
import { SidebarContext } from "../../Context/SidebarContext";
import { wp } from "../../Factories/Wordpress";
import { IBundleProduct } from "../../Interfaces/IBundleProduct";

interface IProps {
    children: ReactNode|ReactNode[]|string
    className: string
    action: string
    data?: { [s: string]: string|boolean|number; }
}

const CTA = (props: IProps) => {
    const [bundles, setBundles] = useState<IBundleProduct[]>([]);

    const classes = [
        'button',
        'cta'
    ];

    classes.push(props.className);
    classes.push('arrow-right');

    const { action } = props;

    useEffect(() => {
        const hasCustomAction = action.split(':').length;
        if (hasCustomAction) {
            (async () => {
                const [target] = action.split(':');

                switch (target) {
                    case 'bundle':
                        setBundles(await wp().bundelSets.get());
                    break;
                }
            })();
        }
    }, [action]);

    if (action.split(':').length > 1) {
        const [target, id] = action.split(':');
        let url;

        switch (target) {
            case 'bundle':
                url = bundles.find((bundle) => (bundle.id === parseInt(id, 10)))?.url;
            break;
        }
        if (!url) {
            return <></>
        }
        return (
            <Button
                className={classes.join(' ')}
                to={url}>{props.children}</Button>
        );
    }

    return (
        <>
            <SidebarContext.Consumer>
                {({setOpen, setElement, setData}) => {
                    return (
                        <Button
                            className={classes.join(' ')}
                            onClick={() => {
                                setOpen(true);
                                setElement(action);
                                setData(props.data);
                            }}>{props.children}</Button>
                    );
                }}
            </SidebarContext.Consumer>
        </>
    );
}

export default CTA;