import React from "react";
import { BrowserRouter as ReactRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async';
import "./Router.scss";
import AppContainer from "../AppContainer/AppContainer";
import { ScrollToTop } from "../../Components/ScrollToTop/ScrollToTop";


export function Router() {

    return (
        <HelmetProvider>
            <ReactRouter>
                <ScrollToTop />
                {/*<Helmet>*/}
                {/*    <title>EPS | Leverancier van ‘state of the art’ schoolmeubilair en het inrichten van een leerplein</title>*/}
                {/*    <meta name="description" content="EPS is leverancier van o.a. schoolmeubilair, schoolstoelen, ophangsystemen, speeltoestellen en het inrichten van een leerplein voor het onderwijs. Wilt u meer weten over schoolmeubilair, schoolstoelen, speeltoestellen of het leerplein voor het onderwijs? Lees dan meer hier..." />*/}
                {/*</Helmet>*/}
                <AppContainer/>
            </ReactRouter>
        </HelmetProvider>
    );
}
