import "./SearchButton.scss";
// import research from "./../../../resources/img/icons/research.svg";
import React from "react";
// import { Icon } from "../../Icon/Icon";

interface IProps {
    searchOpen: boolean
    onClick: () => void
    show: boolean
}

export function SearchButton(props: IProps) {
    const className = ["search-button-wrapper"];
    if (props.searchOpen) {
        className.push("search-open");
    }
    if (props.show) {
        className.push("show");
    }

    return (
        <>
            <div className={className.join(' ')} onClick={props.onClick}>
                {/*<Icon src={research} className={"black"} />*/}
            </div>

        </>
    );
}