import React, { useState } from "react";
import "./ActionBar.scss";
import background from "../../resources/img/shapes/action-bar-background.svg";
import CTA from "../CTA/CTA";

interface IProps {
    action: string
    actionText: string
    title: string
    data?: { [s: string]: string|boolean|number; }
    className?: string;
}

export default function ActionBar(props: IProps) {
    const [name, setName] = useState();
    const [email, setEmail] = useState();

    const data = {
        name,
        email,
        ...props.data
    };

    return (
        <div className={`action-bar-wrapper ${props.className ?? ''}`}>
            <div className="background-wrapper">
                <img src={background} alt="background"/>
            </div>
            <div className="contents-wrapper">
                <div className="action-content-col">
                    <div className="action-content-container">
                        <h1>{props.title}</h1>
                    </div>
                </div>
                <div className="action-content-col form-wrapper">
                    <div className="action-content-container">
                        <input type="text" placeholder={'je naam'} onChange={(e) => setName(e.target.value)}/>
                        <input type="email" placeholder={'je emailadres'} onChange={(e) => setEmail(e.target.value)}/>
                    </div>
                </div>
                <div className="action-content-col">
                    <div className="action-content-container">
                        <CTA className={'button secondary text-right'} action={props.action} data={data}>{props.actionText}</CTA>
                    </div>
                </div>
            </div>
        </div>
    )
}
