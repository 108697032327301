import "./Logo.scss";
import React, { useMemo } from "react";
import payoff from "../../resources/img/shapes/payoff.svg";
import smallPayoff from "../../resources/img/shapes/small-payoff.svg";
import lines from "../../resources/img/shapes/lines.svg";

interface IProps {
    inline?: boolean
    payoffOnRight?: boolean
    showPayoff?: boolean
    delay?: number
    animated?: boolean
}

export function Logo(props: IProps) {
    const classes = useMemo(() => {
        const classes = ['logo-wrapper'];
        if (props.inline) {
            classes.push('inline');
        }
        if (props.payoffOnRight) {
            classes.push('payoff-on-right');
        }
        if (props.animated) {
            classes.push('animated');
        }
        if (props.showPayoff && !props.payoffOnRight) {
            classes.push('with-small-payoff')
        }
        return classes;
    }, [props.inline, props.payoffOnRight, props.animated, props.showPayoff]);

    const payoffElem = useMemo(() => {
        return props.payoffOnRight ? (
            <div className={"payoff-wrapper" + (props.showPayoff ? ' show' : '')}>
                <img src={payoff} alt="Richt je toekomst in"/>
            </div>
        ) : (props.showPayoff ? (
                <div className={"small-payoff-wrapper" + (props.showPayoff ? ' show' : '')}>
                    <img src={smallPayoff} alt="Richt je toekomst in"/>
                </div>
            )
            : <></>);
    }, [props.payoffOnRight, props.showPayoff]);

    return (
        <>
            <div className={classes.join(' ')} data-delay={props.delay}>
                {useMemo(() => (
                    <button className="logo">
                        <div className="logo-content">
                            <div className="letters-wrapper">
                                {(new Array(3 * 6)).fill(null).map((_: any, rowIndex: number) => {
                                    return <span key={rowIndex}>
                                        {[0, 9, 17].includes(rowIndex) ? (
                                            <span className="lines-wrapper">
                                                <img src={lines} alt="lines"/>
                                            </span>
                                        ) : undefined}
                                    </span>
                                })}
                            </div>
                            <div className="subtitle">
                                {/*<span>european</span>*/}
                                {/*<span>presentation</span>*/}
                                {/*<span>systems</span>*/}
                            </div>
                        </div>
                    </button>
                ), [])}

                {payoffElem}
            </div>
        </>
    );
}