import React, { useEffect, useState } from "react";
import "./MobileFooterMenu.scss";
import { IMenuItem } from "../../Interfaces/IMenuItem";
import { wp } from "../../Factories/Wordpress";
import { Menu } from "../Menu/Menu";

interface IProps {

}

export function MobileFooterMenu(props: IProps) {
    const [inView, setInView] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [menu, setMenu] = useState();

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setInView(true);
                }
            });
        });
        observer.observe(document.querySelector('.mobile-footer-menu-wrapper') as Element);
        return () => observer.disconnect();
    }, []);

    useEffect(() => {
        if (!inView) return;
        if (!isLoading && !menu) {
            setIsLoading(true);
            const placeMenu = (result: any[]) => {
                window.localStorage.setItem('cached-mobile-footer-menu', JSON.stringify(result));
                setMenu(result);
            };

            const cached = window.localStorage.getItem('cached-mobile-footer-menu');
            if (cached) {
                const result = JSON.parse(cached) as IMenuItem[];
                placeMenu(result);
            }
            wp().menus.get({menu: 'mobile-footer-menu'}, undefined, 100).then((items: any[]) => {
                placeMenu(items.map((item: any) => {
                    return {
                        name: item.post_title,
                        link: item.url
                    } as IMenuItem;
                }));
            })
        }
    }, [isLoading, menu, inView]);


    return (
        <div className="mobile-footer-menu-wrapper show-for-small-only">
            <Menu menu={((menu ? menu : []) as IMenuItem[]).map((menuItem: IMenuItem) => {
                return {
                    name: menuItem.name,
                    link: menuItem.link
                } as IMenuItem;
            })}/>
        </div>
    )
}