import "./PhoneButton.scss";
import phone from "./../../../resources/img/icons/phone.svg";
import React from "react";
import { Icon } from "../../Icon/Icon";

interface IProps {
    show: boolean
}

export function PhoneButton(props: IProps) {
    const className = ["phone-button-wrapper"];
    if (props.show) {
        className.push("show");
    }

    return (
        <>
            <a className={className.join(' ')} href="tel:+31321332655">
                <Icon src={phone} className={"white"} />
            </a>

        </>
    );
}