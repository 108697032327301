import Wordpress from "squde-wp-api";
import { ISpace } from "../Interfaces/ISpace";
import { ICase } from "../Interfaces/ICase";
import { IProduct } from "../Interfaces/IProduct";
import { IBundleProduct } from "../Interfaces/IBundleProduct";
import { ITerm } from "../Interfaces/ITerm";

export const BUNDLES_CATEGORY_NAME = 'Bundels';

export interface ISlugResult {
    ID: string
    type: string
}

class CustomWordpress extends Wordpress {
    get spaces() {
        const api = this;
        return {
            get: (params: { [s: string]: string|boolean; } = {}, signal: any = null, returnResponse: boolean = false): any => {
                return api.get('spaces/?' + api.buildQuery(params), signal, undefined, returnResponse)
                    .then((result: ISpace[]) => result);
            }
        }
    }
    get space() {
        const api = this;

        return {
            get: (params: any, signal: any = null, returnResponse: boolean = false): Promise<ISpace|undefined> => {
                return api.spaces.get(params, signal, returnResponse)
                    .then((result: ISpace[]) => {
                        if (result[0]) {
                            return result[0];
                        }
                        return undefined;
                    });
            }
        }
    }
    get cases() {
        const api = this;
        return {
            get: (params: { [s: string]: string|boolean|number; } = {}, signal: any = null, returnResponse: boolean = false): any => {
                return api.get('cases/?' + api.buildQuery(params), signal, undefined, returnResponse)
                    .then((result: ICase[]) => result);
            }
        }
    }
    get case() {
        const api = this;

        return {
            get: (params: any, signal: any = null, returnResponse: boolean = false): Promise<ICase|undefined> => {
                return api.cases.get(params, signal, returnResponse)
                    .then((result: ICase[]) => {
                        if (result[0]) {
                            return result[0];
                        }
                        return undefined;
                    });
            }
        }
    }
    get bundelSets() {
        const api = this;
        return {
            get: (params: { [s: string]: string; } = {}, signal: any = null, returnResponse: boolean = false): any => {


                return (async () => {
                    const categories: ITerm[] = Object.values(await api.get('product-categories', signal)) as ITerm[];
                    let bundelCategoryId: string | number | null = window.localStorage.getItem('bundle-category-id')
                    if (!bundelCategoryId) {
                        bundelCategoryId = (categories ?? [])
                            .find((category) => category.name === BUNDLES_CATEGORY_NAME)
                            ?.term_id ?? 0;
                        if (bundelCategoryId) {
                            window.localStorage.setItem('bundle-category-id', bundelCategoryId.toString());
                        }
                    }

                    return (await api.products.get({
                        ...params,
                        category: bundelCategoryId as string,
                    }, signal, returnResponse) as IBundleProduct[]).map((bundle) => {
                        bundle.url = bundle.url.split('/product/').join('/bundels/');
                        return bundle;
                    });
                })();
            }
        }
    }
    get bundelSet() {
        const api = this;

        return {
            get: (params: any, signal: any = null, returnResponse: boolean = false): Promise<IProduct|undefined> => {
                return api.bundelSets.get(params, signal, returnResponse)
                    .then((result: IProduct[]) => {
                        if (result[0]) {
                            return result[0] as IProduct;
                        }
                        return undefined;
                    });
            }
        }
    }

    get slug() {
        const api = this;
        return {
            get: (params: { [s: string]: string; } = {}, signal: any = null): Promise<ISlugResult> => {
                return api.get('slug/?' + api.buildQuery(params), signal)
                    .then((result: any) => result);
            }
        }
    }

    get auth2() {
        const api = this;
        return {
            forgot: (email: string, signal: any = null): Promise<any> => {
                return api.post('user/forgotPassword', {user_login: email}, signal)
                    .then((response: any) => {
                        if ('done' in response) {
                            return response;
                        }
                        throw response;
                    });
            },
            resetPassword: (params: { [s: string]: string; } = {}, signal: any = null): Promise<any> => {
                return api.post('user/resetPassword', params, signal);
            }
        };
    }
}
export function wpHost(): string {
    const endpoints = {
        dev: 'http://eps-presentation.local',
        wpprov: 'https://eps.wpprovider.nl',
        test: 'https://eps.squde.nl',
        prod: 'https://eps-presentation.nl'
    };
    let endpoint;
    const host = window.location.host;
    if (host === 'eps-presentation.nl') {
        endpoint = endpoints.prod;
    } else if (host === 'eps.wpprovider.nl') {
        endpoint = endpoints.wpprov;
    } else if (host === 'eps.squde.nl') {
        endpoint = endpoints.test;
    } else {
        endpoint = endpoints.dev
    }
    return endpoint;
}
export function getStaticUrl(params?: any): string {
    let url = window.location.toString();
    url = url.split('localhost').join('eps-presentation.local');
    url = url.split('://').join('://static.');
    url = url.split(':3000').join('');

    if (params) {
        url = url.split(window.location.hash).join('');
        const separator = url.split('?').length === 1 ? '?' : '&';
        const query = new URLSearchParams(params);
        url = `${url}${separator}${query}`;
    }

    return url;
}
export function wp() {
    const endpoint = wpHost();
    return new CustomWordpress(endpoint + "/wp-json/wp/v2/");
}
