import React from "react";
import "./MenuButton.scss";
import { withRouter } from 'react-router-dom';


interface IProps {
    menuOpen: boolean
    onClick: () => void
    history?: any
}

function MenuButton(props: IProps) {
    const className = ["menu-button-wrapper"];
    if (props.menuOpen) {
        className.push("menu-open");
    }
    if (props.history) {
        props.history.listen((location: any, action: any) => {
            if (props.menuOpen) {
                props.onClick();
            }
        });
    }

    return (
        <>
            <div className={className.join(' ')} onClick={props.onClick}>
                <div className="hamburger">
                    <div className="line" />
                    <div className="line" />
                    <div className="line" />
                </div>
                <div className="cross">
                    <div className="line" />
                    <div className="line" />
                </div>
            </div>
        </>
    );
}
// @ts-ignore
export default withRouter(MenuButton);