import React, { useEffect, useRef, useState } from "react";
import "./NewsletterSubscribe.scss";
import mail from "../../resources/img/icons/mail-blue.svg";
import { Field } from "../../Containers/Field/Field";
import { SubmissionForm } from "../SubmissionForm/SubmissionForm";
import { getStaticUrl } from "../../Factories/Wordpress";
import { ChangingTitles } from "./ChangingTitles/ChangingTitles";

interface IProps {

}

export function NewsletterSubscribe(props: IProps) {
    const ref = useRef<HTMLDivElement>(null);
    const [initClass, setInitClass] = useState('init');
    const [open, setOpen] = useState(false);
    const [error, setError] = useState('');
    const [autoOpenState, setAutoOpenState] = useState({ opened: false, closed: false });

    useEffect(() => {
        setTimeout(() => {
            setInitClass('');
        }, 2000);
    }, []);

    useEffect(() => {
        if (open && ref.current) {
            (ref.current.querySelector('input'))?.focus();

            const elm = ref.current;
            if (!elm) return;

            // Close when clicked outside
            const onClick = (e: MouseEvent) => {
                if (elm.contains(e.target as Node)) {
                    return;
                }
                toggleOpen(false);
            }
            window.addEventListener('click', onClick);
            return () => {
                window.removeEventListener('click', onClick);
            }
        }
    }, [open, ref]);


    const toggleOpen = (desiredState: boolean) => {
        if (!desiredState) {
            setAutoOpenState((prevState) => {
                return {
                    ...prevState,
                    closed: true,
                }
            })
        }
        setOpen(desiredState);
    }

    const handleSubmit = (value: any, setIsLoading: (state: boolean) => void, setDone: (state: boolean) => void) => {
        setIsLoading(true);

        setError('');

        const url = getStaticUrl({
            'mc4wp-subscribe': '1',
            email: value.email,
        });
        fetch(url, {
            "method": "GET",
        }).then(() => {
            setDone(true);
        }).catch(() => {
            setError('Er ging iets mis, probeer het opnieuw');
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const isCurrentlyOpen = (open || (autoOpenState.opened && !autoOpenState.closed));

    return (
        <div className={`newsletter-subscribe-wrapper ${isCurrentlyOpen ? 'open' : ''} ${initClass}`} ref={ref}>
            <div className="newsletter-subscribe-label-wrapper" onClick={() => toggleOpen(!isCurrentlyOpen)}>
                <div className="newsletter-subscribe-label">
                    <div className="newsletter-subscribe-label-inner">
                        <img src={mail} alt="Ma"/>
                        <ChangingTitles playing={!initClass && !open}>
                            <span>Schrijf je in voor onze nieuwsbrief</span>
                            <span>Blijf op de hoogte</span>
                            <span>Lees de laatste ontwikkelingen</span>
                            <span>Nieuwsbrief ontvangen?</span>
                        </ChangingTitles>
                    </div>
                </div>
            </div>
            <div className="newsletter-subscribe-form">
                <SubmissionForm before={(
                    <>
                        <p>Meld je aan voor onze nieuwsbrief</p>
                        {error.length > 0 ? <p className={"error"}>{error}</p> : ''}
                    </>
                )} formName={'Newsletter'} buttonText={'Aanmelden'} onSubmit={handleSubmit}>
                    <section>
                        <Field name="email" title="Email" inputType="text" required={true} value={''} />
                    </section>
                </SubmissionForm>
            </div>
        </div>
    )
}
