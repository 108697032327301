import React, { ReactElement, useState } from "react";
import get from "lodash/get";
import { SidebarContext } from "../../Context/SidebarContext";
import { Sidebar } from "../../Components/Sidebar/Sidebar";
import { Default } from "./Default/Default";
import { QuickScan } from "./QuickScan/QuickScan";
import { BundleContactForm } from "./BundleContactForm/BundleContactForm";

const actions = {
    default: Default,
    quickScan: QuickScan,
    bundleForm: BundleContactForm,
};

interface IProps {
    children: ReactElement|ReactElement[]|string
}

export function SidebarContainer({children}: IProps) {
    const [open, setOpen] = useState<boolean>(false);
    const [element, setElement] = useState();
    const [data, setData] = useState();

    return (
        <SidebarContext.Provider value={{
            open,
            setOpen,
            element,
            setElement,
            setData
        }}>
            {children}
            <Sidebar>
                {element ? React.createElement(get(actions, element), {data}) : <></>}
            </Sidebar>

        </SidebarContext.Provider>
    );
}
