import React, { useEffect, useState } from "react";
import "./FooterSocials.scss";
import { IMenuItem } from "../../Interfaces/IMenuItem";
import { wp } from "../../Factories/Wordpress";
import facebook from "../../resources/img/icons/facebook-dark-blue.svg";
import twitter from "../../resources/img/icons/twitter-dark-blue.svg";
import linkedin from "../../resources/img/icons/linkedin-dark-blue.svg";
import instagram from "../../resources/img/icons/instagram-dark-blue.svg";
import whatsapp from "../../resources/img/icons/whatsapp-dark-blue.svg";

const images = {
    facebook,
    twitter,
    linkedin,
    instagram,
    whatsapp
};

interface IProps {

}

export function FooterSocials(props: IProps) {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [menu, setMenu] = useState();
    const [inView, setInView] = useState<boolean>(false);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setInView(true);
                }
            });
        });
        observer.observe(document.querySelector('.footer-socials-wrapper') as Element);
        return () => observer.disconnect();
    }, []);

    useEffect(() => {
        if (!inView) return;
        if (!isLoading && !menu) {
            setIsLoading(true);
            const placeMenu = (result: any[]) => {
                window.localStorage.setItem('cached-socials-menu', JSON.stringify(result));
                setMenu(result);
            };

            const cached = window.localStorage.getItem('cached-socials-menu');
            if (cached) {
                const result = JSON.parse(cached) as IMenuItem[];
                placeMenu(result);
            }
            wp().menus.get({menu: 'socials'}, undefined, 100).then((items: any[]) => {
                placeMenu(items.map((item: any) => {
                    return {
                        name: item.post_title,
                        link: item.url
                    } as IMenuItem;
                }));
            })
        }
    }, [isLoading, menu, inView]);

    return (
        <div className="footer-socials-wrapper" data-contains={menu ? menu.length : 0}>
            {menu ? menu.map((item: IMenuItem, index: number) => {
                // @ts-ignore
                const src = images[item.name.toLowerCase()];
                return (
                    <a key={index} href={item.link} className={item.name.toLowerCase()}>
                        <img src={src} alt={item.name}/>
                    </a>
                );
            }) : <></>}
        </div>
    )
}