import React, { ReactElement } from "react";
import { Field } from "../../Field/Field";
import { CheckboxInput } from "../../../Components/CheckboxInput/CheckboxInput";
import { SubmissionForm } from "../../../Components/SubmissionForm/SubmissionForm";
import { SidebarContext } from "../../../Context/SidebarContext";

interface IProps {
    heading?: ReactElement|ReactElement[]|string
    buttonClass?: string
    data?: { [s: string]: string|boolean|number; }
    leaveOpen?: boolean
}

export function Default({buttonClass, heading, data, leaveOpen}: IProps) {
    let name: string;
    if (data && data.name) {
        name = data.name as string;
    }
    let email: string;
    if (data && data.email) {
        email = data.email as string;
    }
    const phone = data?.phone;
    return (
        <SidebarContext.Consumer>
            {({setOpen}) => {
                return <SubmissionForm leaveOpen={leaveOpen} buttonClass={buttonClass} before={(heading ||
                    <>
                        <h1>Gratis adviesgesprek</h1>
                        <p>Wat speelt er binnen de school? Waar ligt de focus? Allemaal vragen die we bespreken tijdens een gratis adviesgesprek. Laat je gegevens achter en wij nemen zo snel mogelijk contact met je op.</p>
                        <p>Liever telefonisch contact opnemen? - Dat kan ook via (0321) 33 26 55.</p>
                    </>
                )} formName={'Contact'} buttonText={"Aanvragen"} whenDone={() => {
                    setTimeout(() => {
                        setOpen(false);
                    }, 5000);
                }}>
                    <section>
                        <Field name="name" title="Naam" inputType="text" required={true} value={name} />
                        <Field name="functie" title="Functie" inputType="text" />
                        <Field name="school" title="School" inputType="text" required={true} />
                        <Field name="email" title="Email" inputType="text" required={true} value={email} />
                        <Field name="phone" title="Telefoonnummer" inputType="phone" value={phone} />
                    </section>
                    <section>
                        <h4>Ik ontvang graag informatie over:</h4>
                        <CheckboxInput title={"Advies"} type={"checkbox"} name={"informatie-over[]"} value={"advies"} />
                        <CheckboxInput title={"Ontwerp"} type={"checkbox"} name={"informatie-over[]"} value={"ontwerp"} />
                        <CheckboxInput title={"Flexibel meubilair"} type={"checkbox"} name={"informatie-over[]"} value={"flexibel-meubilair"} />
                        <CheckboxInput title={"Ophangsystemen"} type={"checkbox"} name={"informatie-over[]"} value={"ophangsystemen"} />

                        <Field name={"agreements"} title={"Ik ga akkoord met de algemene voorwaarden"} inputType={"checkbox"} required={true} />
                    </section>
                </SubmissionForm>;
            }}
        </SidebarContext.Consumer>
    );
}
