import "./TopBar.scss";
import React, { useEffect, useMemo, useState } from "react";
import MenuButton  from "./MenuButton/MenuButton";
import { SearchButton } from "./SearchButton/SearchButton";
import { Logo } from "../Logo/Logo";
import { PhoneButton } from "./PhoneButton/PhoneButton";

const TopMenu = React.lazy(() => import("./TopMenu/TopMenu"));

interface IProps {

}

export function TopBar(props: IProps) {
    const [showMenu, setShowMenu] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [showSmallLogo, setShowSmallLogo] = useState(false);

    useEffect(() => {
        const onScroll = () => {
            const scrollTop = document.getElementsByTagName('html')[0].scrollTop as number;

            setShowSmallLogo(scrollTop > 100);
        };
        window.addEventListener('scroll', onScroll);

        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    });

    return (
        <>
            {useMemo(() => <Logo animated={true}/>, [])}
            <div className="top-bar">
                <SearchButton show={!showMenu} searchOpen={showSearch} onClick={() => setShowSearch(!showSearch)}/>
                <MenuButton menuOpen={showMenu} onClick={() => setShowMenu(!showMenu)}/>
                <PhoneButton show={!showMenu} />
            </div>
            <TopMenu
                showMenu={showMenu}
                showSmallLogo={showSmallLogo}
                closeFn={() => setShowMenu(false)}
            />
        </>
    );
}