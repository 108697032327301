import React from "react";
import { Field } from "../../Field/Field";
import { PhoneInput } from "../../../Components/PhoneInput/PhoneInput";
import { SubmissionForm } from "../../../Components/SubmissionForm/SubmissionForm";
import { SidebarContext } from "../../../Context/SidebarContext";

interface IProps {
    data?: { [s: string]: string|boolean|number; }
}

export function QuickScan({data}: IProps) {

    let name: string;
    if (data && data.name) {
        name = data.name as string;
    }
    let email: string;
    if (data && data.email) {
        email = data.email as string;
    }

    return (
        <SidebarContext.Consumer>
            {({setOpen}) => {
                return <SubmissionForm before={(
                    <>
                        <h1>Hoe maak ik mijn school aantrekkelijk en geschikt voor gepersonaliseerd onderwijs met een beperkt budget?</h1>
                        <p><b>Tijdens een Quick scan van 45 minuten helpen wij u aan het antwoord op bovenstaande vraag.</b>
                        <ul style={{paddingLeft: 0}}>
                            <li >
                                We maken met een frisse en objectieve blik glashelder wat voor u de begrippen aantrekkelijk en gepersonaliseerd onderwijs concreet betekenen en welke inrichting daar voor u het beste bij past.
                            </li>
                            <li>
                                We stellen vast wat het gat is tussen uw wensen en de beschikbare middelen.
                            </li>
                            <li>
                                We reiken u drie opties aan hoe in uw specifieke geval het gat tussen wensen en middelen overbrugd kan worden.
                            </li>
                        </ul></p>
                    </>
                )} formName={'Quickscan'} buttonText={"Aanvragen"} whenDone={() => {
                    setTimeout(() => {
                        setOpen(false);
                    }, 5000);
                }}>
                    <section>
                        <Field name="name" title="Naam" inputType="text" required={true} value={name} />
                        <Field name="school" title="School" inputType="text" required={true} />
                        <Field name="email" title="Email" inputType="text" required={true} value={email} />
                        <PhoneInput name="telefoonnummer" title="Telefoonnummer" required={true} />

                    </section>

                    <Field name={"agreements"} title={"Ik ga akkoord met de algemene voorwaarden"} inputType={"checkbox"} required={true} />

                </SubmissionForm>;
            }}
        </SidebarContext.Consumer>
    );
}