import React, { useEffect, useMemo, useRef, useState } from "react";
import "./ChangingTitles.scss";

interface IProps {
    playing?: boolean;
}

export function ChangingTitles({ children, playing = true }: React.PropsWithChildren<IProps>) {
    const ref = useRef<HTMLDivElement>(null);
    const [texts, setTexts] = useState<string[]>([]);
    const [activeText, setActiveText] = useState<string>('');
    const [showText, setShowText] = useState<boolean>(false);
    const [hovered, setHovered] = useState<boolean>(false);

    const isPlaying = playing && !hovered;

    // Get the children texts
    useEffect(() => {
        const elm = ref.current;
        if (!elm) return;
        const texts: string[] = [];
        elm.childNodes.forEach((child) => {
            if (child instanceof HTMLSpanElement) {
                texts.push(child.innerText);
            }
        });
        setTexts(texts);
    }, []);

    // Set the play animation
    useEffect(() => {
        if (!isPlaying) return;
        const interval = setInterval(() => {
            setShowText((prevState) => !prevState);
        }, 2000);

        return () => {
            clearInterval(interval);
        }
    }, [isPlaying]);

    // When paused, make sure one text is shown
    useEffect(() => {
        if (!isPlaying) {
            setShowText(true);
        } else {
            setShowText(false);
        }
    }, [isPlaying]);

    // When hovered, pause the animation
    useEffect(() => {
        const onMouseEnter = () => {
            setHovered(true);
        }
        const onMouseLeave = () => {
            setHovered(false);
        }
        const elm = ref.current;
        if (!elm) return;
        elm.addEventListener('mouseenter', onMouseEnter);
        elm.addEventListener('mouseleave', onMouseLeave);
        return () => {
            elm.removeEventListener('mouseenter', onMouseEnter);
            elm.removeEventListener('mouseleave', onMouseLeave);
        }
    }, []);

    // Increment the active text
    useEffect(() => {
        if (showText) {
            setActiveText((prevState) => {
                const index = texts.indexOf(prevState);
                if (index === texts.length - 1) {
                    return texts[0];
                }
                return texts[index + 1];

            })
        }
    }, [showText, texts]);

    const activeIndex = texts.indexOf(activeText);
    const width = useMemo(() => {
        return showText ? (() => {
            if (!ref.current) return 0;
            const elm = ref.current.childNodes[activeIndex] as HTMLSpanElement;
            if (!elm) return 0;
            return `${elm.offsetWidth}px`;
        })() : 0;
    }, [showText, activeIndex]);

    const classes = ['changing-titles', `active-${activeIndex}`, ...(showText ? ['show'] : [])];

    return (
        <div className={classes.join(' ')} style={{ width }} ref={ref}>
            { children }
        </div>
    )
}