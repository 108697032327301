import React from "react";
import "./Copyright.scss";

interface IProps {

}

export function Copyright(props: IProps) {
    return (
        <div className="copyright-wrapper">
            Copyright { (new Date()).getFullYear() }
        </div>
    )
}