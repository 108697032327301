import { useEffect, useState } from "react";
import { BUNDLES_CATEGORY_NAME, wp } from "../Factories/Wordpress";
import { IProduct } from "../Interfaces/IProduct";
import first from "lodash/first";
import orderBy from "lodash/orderBy";
import uniqBy from "lodash/uniqBy";
import { IBundleCategory, IBundleProduct } from "../Interfaces/IBundleProduct";

interface UseBundlesResult {
    bundles: IBundleProduct[];
    types: IBundleCategory[];
}

export const useBundles = (loadRightAway: boolean = true): UseBundlesResult => {
    const [bundles, setBundles] = useState<IProduct[]>([]);

    const fetchBundles = async () => {
        setBundles(await wp().bundelSets.get());

    };

    useEffect(() => {
        if (loadRightAway) {
            fetchBundles();
        }
    }, [loadRightAway]);

    const result = orderBy(bundles.map((bundle) => {
        const result = bundle as IBundleProduct;
        const { categories } = bundle;
        const otherCategories = categories.filter((category) => category.name !== BUNDLES_CATEGORY_NAME);
        const bundleType = first(otherCategories) as IBundleCategory;
        if (bundleType) {
            result.bundle_type = bundleType;
        }

        return result;
    }).filter((bundle) => {
        return Object.keys(bundle).includes('bundle_type');
    }), ['menu_order'], ['asc']);

    const types = uniqBy(result.map((bundle) => bundle.bundle_type), 'id');

    return {
        bundles: result,
        types,
    }
}
