import React from "react";
import { Link } from "react-router-dom";

const relativeDomains = [
    'eps-presentation.local',
    'localhost:3000',
    'eps-presentation.nl',
];

export function ContentLink({ children, href, ref, ...props }: React.HTMLProps<HTMLAnchorElement>) {
    if (href && relativeDomains.some((domain) => href.includes(domain))) {
        const relativePath = `/${href.split('/').slice(3).join('/')}`;
        return (
            <Link to={relativePath} {...props}>{children}</Link>
        )
    }
    return (
        <a {...props} href={href} ref={ref}>{children}</a>
    )
}