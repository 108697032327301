import React, { ReactElement } from "react";
import "./Sidebar.scss";
import x from "../../resources/img/icons/X.svg";
import { SidebarContext } from "../../Context/SidebarContext";

interface IProps {
    children: ReactElement|ReactElement[]|string
}

export function Sidebar(props: IProps) {
    const classes = ['sidebar-wrapper'];



    return (
        <SidebarContext.Consumer>
            {({open, setOpen}) => {
                if (open) {
                    classes.push('show');
                }
                return (
                    <div className={classes.join(' ')}>
                        <div className="close-button" onClick={() => setOpen(false)}>
                            <img src={x} alt="x"/>
                        </div>
                        <div className="sidebar-contents">
                            {props.children}
                        </div>
                    </div>
                );
            }}
        </SidebarContext.Consumer>
    )
}