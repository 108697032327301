import React, { Suspense, useEffect, useState } from "react";
import get from "lodash/get";
import { wp } from "../../Factories/Wordpress";
import { IPage } from "squde-wp-api/IPage";
import { Content } from "../../Components/Content/Content";
import { HelmetTitle } from "../../Components/HelmetTitle/HelmetTitle";
import { Loader } from "../../Components/Loader/Loader";

interface IProps {
    history: any
    match: {params: {slug: string}}
    type?: string
}

export function Page(props: IProps) {
    const [lastSlug, setLastSlug] = useState<string>('');
    const [page, setPage] = useState<IPage|undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const slug = props.match.params.slug;

    useEffect(() => {
        if (slug !== lastSlug) {
            setIsLoading(true);
            const endpoint = slug ? (() => {
                return props.type ? get(wp(), props.type) : wp().page;
            })().get({slug}) : wp().frontpage.get();
            endpoint.then((result: IPage|undefined) => {
                if (result) {
                    const dataLayer = get(window, 'dataLayer');
                    dataLayer?.push({
                        event: 'pageview',
                        page: {
                            url: window.location.pathname + window.location.search,
                            title: result.title,
                            path: result.title,
                        }
                    });

                    setPage(result);
                } else {
                    setPage(undefined);
                }
            }).finally(() => {
                setLastSlug(slug);
                setIsLoading(false);
            })
        }
    }, [lastSlug, props.type, slug]);


    if (!page && !isLoading) {
        return <>Unknown page</>
    }
    if (isLoading || !page) {
        return null;
    }
    return (
        <>
            <HelmetTitle/>

            <Suspense fallback={<Loader />}>
                <div className="page-page-wrapper">
                    <Content>{page}</Content>
                </div>
            </Suspense>

        </>
    )
}
