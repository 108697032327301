import "./Debug.scss";
import React from "react";

export function Debug() {
    return <div id="debug" />;
}

export function debug(info: any) {
    const debugDiv = document.getElementById('debug');
    if (debugDiv && info) {
        debugDiv.innerHTML = "<pre>" + JSON.stringify(info, undefined, 4) + "</pre>";
    }
}