import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import parse  from 'html-react-parser';
import { getStaticUrl } from "../../Factories/Wordpress";

interface IProps {
    tempTitle?: string
}

const titles: {[url: string]: string} = {};

const abortController = new AbortController();

export function HelmetTitle(props: IProps) {
    const [title, setTitle] = useState<string|undefined>(props.tempTitle);

    const url = getStaticUrl();

    useEffect(() => {
        if (window.location.href.split('localhost:3000').length > 1) {
            return;
        }
        if (titles[url]) {
            setTitle(titles[url]);
        } else {
            fetch(url, {
                signal: abortController.signal,
            })
                .then((result: Response) => result.text())
                .then((html: string) => {
                    const newTitle = html.split("\n").filter((line: string) => {
                        return line.split('<title').length > 1;
                    })[0];
                    const parsed = parse(newTitle.split('>')[1].split('<')[0]).toString();
                    setTitle(parsed);
                    if (!titles[url]) {
                        titles[url] = parsed;
                    }
                })
                .catch((e) => {
                    console.error(e);
                });
        }
        return () => {
            // abortController.abort();
        }
    }, [url]);

    if (!title) {
        return <></>;
    }

    return (
        <HelmetProvider>
            <Helmet>
                <title>{title}</title>
            </Helmet>
        </HelmetProvider>
    )
}
