import "./Footer.scss";
import React from "react";
import { FooterMenu } from "./FooterMenu/FooterMenu";
import { Logo } from "../Logo/Logo";
import { LatestNews } from "./LatestNews/LatestNews";
import { FooterSocials } from "../FooterSocials/FooterSocials";
import { MobileFooterMenu } from "../MobileFooterMenu/MobileFooterMenu";
import { Copyright } from "../Copyright/Copyright";

interface IProps {

}

export function Footer(props: IProps) {
    return (
        <div className={"footer-wrapper"}>
            <Logo inline={true} showPayoff={true}/>
            <LatestNews/>
            <FooterMenu/>
            <FooterSocials/>
            <MobileFooterMenu/>
            <Copyright/>
        </div>
    );
}