import "./PageLoader.scss";
import React from "react";
import { Loader } from "../Loader/Loader";

interface IProps {
}



export function PageLoader() {
    return (
        <div className={'page-loader'}>
            <div className="loader-wrapper">
                <Loader loading={true}/>
            </div>
        </div>
    );
}