import "./FooterMenu.scss";
import React, { useEffect, useState } from "react";
import { IMenuItem } from "../../../Interfaces/IMenuItem";
import { Menu } from "../../Menu/Menu";
import { wp } from "../../../Factories/Wordpress";
import { IMenu } from "squde-wp-api/IMenu";

interface IProps {

}

export function FooterMenu(props: IProps) {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [inView, setInView] = useState<boolean>(false);
    const [menu, setMenu] = useState();

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setInView(true);
                }
            });
        });
        observer.observe(document.querySelector('.footer-menu-wrapper') as Element);
        return () => observer.disconnect();
    }, []);

    useEffect(() => {
        if (!inView) return;
        if (!isLoading && !menu) {
            setIsLoading(true);
            const placeMenu = (result: IMenuItem[]) => {
                window.localStorage.setItem('cached-footer-menu', JSON.stringify(result));
                setMenu(result);
            };

            const cached = window.localStorage.getItem('cached-footer-menu');
            const priority = cached ? 10 : 1;
            if (cached) {
                const result = JSON.parse(cached) as IMenuItem[];
                placeMenu(result);
            }
            wp().menus.get({menu: 'footer-menu'}, undefined, priority).then(placeMenu)
                .finally(() => {
                    setIsLoading(false);
                })
        }
    }, [isLoading, menu, inView]);

    const classes = ["footer-menu-wrapper"];

    if (isLoading) {
        classes.push('is-loading');
    }

    const contents = (() => {
        if (menu) {
            return menu.map((menuItem: IMenu, index: number) => {
                return (
                    <div className="footer-menu-section" key={index}>
                        <h4>{menuItem.title}</h4>
                        <Menu menu={(menuItem.subMenu as IMenu[]).map((menuItem: IMenu) => {
                            return {
                                name: menuItem.title,
                                link: menuItem.url
                            } as IMenuItem;
                        })}/>
                    </div>
                );
            })
        }
    })();

    return (
        <div className={classes.join(' ')}>
            {contents}
        </div>
    )
}